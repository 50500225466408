import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import FlashcardInstallations from '@/views/FlashcardInstallations.vue';
import DirectLogin from '@/views/DirectLogin.vue';
import UserSearch from '@/views/UserSearch.vue';
import HomePage from '@/views/HomePage.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		component: HomePage,
	},
	{
		path: '/access',
		component: FlashcardInstallations,
	},
	{
		path: '/directLogin',
		component: DirectLogin,
	},
	{
		path: '/userSearch',
		component: UserSearch,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
