
import Vue from 'vue';
import { isEmpty } from '@/service/objectService';
import { authenticatePodio, isDirectLogin, isUserSearch, podioAuthRouteWithParams } from '@/service/loginService';
import TheRetryLogin from '@/components/TheRetryLogin.vue';

export default Vue.extend({
	data() {
		return {
			showRetryLogin: false,
			warningContent: '',
			accessToken: '',
			target: '' as unknown as URL,
			userIsAuthenticated: false,
			loading: true,
		};
	},

	created() {
		if (localStorage.getItem('timeOutCounter') === null) {
			localStorage.timeOutCounter = 0;
		}

		const urlParameters = this.$route.query;
		const urlPath = this.$route.path;

		if (isEmpty(urlParameters) || (isDirectLogin(urlPath) && !urlParameters.code)) {
			this.redirectToPodioForAuthorization(urlParameters, urlPath);
		}

		if (urlParameters.code) {
			this.tryForPodioAuthorizationToken(urlParameters, urlPath);
		}

		if (urlParameters.error) {
			this.setStateToAccessDeniedWarning();
		}
	},

	methods: {
		redirectToPodioForAuthorization(urlParams: any, urlPath: string) {
			console.info('Redirecting to Podio...');
			window.location.href = podioAuthRouteWithParams(urlParams, urlPath);
		},

		async tryForPodioAuthorizationToken(urlParameters: any, urlPath: string) {
			console.info('Attempting to authorize...');
			try {
				this.accessToken = await this.authenticatePodioWrapper(urlParameters.code);

				this.target = new URL(
					Object.keys(urlParameters)
						.filter((param) => !['state', 'code', 'target'].includes(param))
						.reduce((acc, key) => `${acc}&${key}=${urlParameters[key]}`, `https://${urlParameters.target}`)
				);

				this.setStateToAuthenticated(urlPath);
			} catch (error: any) {
				if (localStorage.timeOutCounter < 3) {
					console.warn('Attempt failed');
					localStorage.timeOutCounter++;
					this.redirectToPodioForAuthorization(urlParameters, urlPath);
				} else if (error.response && error.response.status === 503) {
					localStorage.clear();
					this.setStateToPodioFailedError(error.response.data.message);
				} else {
					localStorage.clear();
					this.setStateToAuthorizationError();
				}
			}
		},

		async authenticatePodioWrapper(code: string): Promise<string> {
			return await authenticatePodio(code);
		},

		setStateToAuthenticated(urlPath: string) {
			console.info('Attempt successful');
			this.userIsAuthenticated = true;
			if (!isDirectLogin(urlPath) && !isUserSearch(urlPath)) {
				this.$router.push('/access');
			}
		},

		setStateToAuthorizationError() {
			this.warningContent =
				'Die Anwendung konnte sich drei mal in Folge Podio gegenüber nicht autorisieren bitte' +
				' versuche es später noch einmal oder melde dich beim Support';
			this.showRetryLogin = true;
		},

		setStateToAccessDeniedWarning() {
			this.warningContent = 'Du kannst die Applikation nicht nutzen, wenn du den Podio-Zugriff verweigerst';
			this.showRetryLogin = true;
		},

		setStateToInstallationError(msg: string) {
			this.warningContent = `${msg} Bitte versuche es später noch einmal oder melde dich beim Support`;
			this.showRetryLogin = true;
			this.loading = false;
		},

		setStateToPodioFailedError(msg: string) {
			this.warningContent = `${msg} Bitte versuche es noch einmal.  Wenn der selbe Fehler bereits mehrmals aufgetreten ist versuche es in ein paar Minuten noch einmal.`;
			this.showRetryLogin = true;
			this.loading = false;
		},

		setStateToMissingHostWarning(msg: string) {
			this.warningContent = msg;
			this.$router.push('/access');
		},

		stopLoading() {
			this.loading = false;
		},
	},
	components: {
		TheRetryLogin,
	},
});
