
import Vue from 'vue';
import { podioAuthRouteWithParams } from '@/service/loginService';
import BaseWarning from '@/components/BaseWarning.vue';

export default Vue.extend({
	data: function () {
		return {
			authorizationUrl: podioAuthRouteWithParams(),
		};
	},

	created() {
		this.$emit('loadingComplete');
	},

	props: {
		warningContent: {
			type: String,
			required: true,
		},
	},
	components: {
		BaseWarning,
	},
});
