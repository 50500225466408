import axios from 'axios';

interface UserSearchInfo {
	id: number;
	firstName: string;
	lastName: string;
	username: string;
	domain: string;
	installationId: number;
}

interface ErrorInfo {
	installation: string;
	message: string;
}

interface UserSearchResponse {
	foundUsers: UserSearchInfo[];
	errors: ErrorInfo[];
}

interface MessageResponse {
	code: number;
	message: string;
}

export async function searchUsers(query: string, accessToken: string): Promise<UserSearchResponse> {
	const response = await axios.get('/api/user/search', {
		params: {
			query: query,
			accessToken: accessToken,
		},
	});

	return Promise.resolve(response.data);
}

export async function deleteUser(
	userId: number,
	installationId: number,
	accessToken: string
): Promise<MessageResponse> {
	const response = await axios.post('/api/user/delete', {
		userId,
		installationId,
		accessToken,
	});

	return Promise.resolve(response.data);
}
