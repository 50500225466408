import axios from 'axios';
import { applicationUrl, clientId, podioPermissionScope } from '../../config';

const podioAuthRoute = 'https://podio.com/oauth/authorize/';
const paramClient = '?client_id=' + clientId;
const paramRedirectBase = '&redirect_uri=' + applicationUrl;
const paramScope = '&scope=' + podioPermissionScope;

export function podioAuthRouteWithParams(urlParams?: any, urlPath?: string) {
	let paramRedirect;
	if (urlParams && urlPath && isDirectLogin(urlPath) && urlParams.target) {
		paramRedirect = paramRedirectBase + `/directLogin?target=${encodeURIComponent(urlParams.target)}`;
	} else if (urlPath && urlPath === '/userSearch') {
		paramRedirect = paramRedirectBase + urlPath;
	} else {
		paramRedirect = paramRedirectBase;
	}
	return podioAuthRoute + paramClient + paramRedirect + paramScope;
}

export function isDirectLogin(urlPath: string) {
	return urlPath.includes('directLogin');
}

export function isUserSearch(urlPath: string) {
	return urlPath.includes('userSearch');
}

export interface InstallationInfo {
	name: string;
	id: number;
}

export async function authenticatePodio(code: string): Promise<string> {
	const response: any = await axios.get('/api/auth', {
		params: {
			authorizationCode: code,
		},
	});

	if (response.data.error) {
		return Promise.reject(response.data.error);
	}

	return Promise.resolve(response.data.accessToken);
}

export async function getFlashcardInstallationList(): Promise<InstallationInfo[]> {
	const response = await axios.get('/api/flashcardInstallation');

	return Promise.resolve(response.data);
}

export async function getFlashcardInstallationByHost(host: string): Promise<InstallationInfo> {
	const response = await axios.get(`/api/flashcardInstallation/${host}`);
	return Promise.resolve(response.data);
}

export function getTokenRouteLink(
	flashcardInstallationId: number,
	accessToken: string,
	deepLink?: string,
	requestedHost?: string
) {
	let tokenRouteLink =
		applicationUrl +
		'/api/token?' +
		'flashcardInstallationId=' +
		flashcardInstallationId +
		'&accessToken=' +
		accessToken;

	if (deepLink) {
		tokenRouteLink += '&deepLink=' + encodeURIComponent(deepLink);
	}

	if (requestedHost) {
		tokenRouteLink += '&requestedHost=' + requestedHost;
	}

	return tokenRouteLink;
}
