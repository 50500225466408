
import Vue from 'vue';
import BaseWarning from '@/components/BaseWarning.vue';
import { deleteUser, searchUsers } from '@/service/userService';

export default Vue.extend({
	components: {
		BaseWarning,
	},

	data() {
		return {
			headers: [
				{ text: 'Benutzername', value: 'username' },
				{ text: 'Vorname', value: 'firstName' },
				{ text: 'Nachname', value: 'lastName' },
				{ text: 'Email', value: 'email' },
				{ text: 'Installation', value: 'domain' },
				{ text: '', value: 'actions', sortable: false },
			],
			foundUsers: [],
			queryString: '',
			loading: false,
			warning: '',
		};
	},

	props: {
		accessToken: {
			type: String,
			required: true,
		},
	},

	created() {
		this.$emit('loadingComplete');
	},

	computed: {
		items() {
			return this.foundUsers.map((item) => ({ key: `${item.id}:${item.installationId}`, ...item }));
		},
	},

	methods: {
		async sendSearchRequest() {
			if (this.queryString.trim() === '') {
				this.warning = 'Der Suchbegriff darf nicht leer sein oder nur aus Leerzeichen bestehen';
			} else {
				this.loading = true;
				this.warning = '';
				this.foundUsers = [];

				try {
					const { foundUsers, errors } = await searchUsers(this.queryString.trim(), this.accessToken);
					this.foundUsers = foundUsers;

					if (errors.length > 0) {
						this.warning =
							'Auf folgenden Installationen schlug die Suche fehl, Details dazu sind in der Konsole:\n' +
							errors.map((error) => error.installation).join('\n');
						console.error('Failed installations: ', errors);
					}
				} catch (e: any) {
					if (e.response.status === 424 || e.response.status === 403 || e.response.status === 503) {
						this.warning = e.response.data.message;
					} else {
						this.warning = 'Unbekannter Fehler, Details dazu sind in der Konsole';
						console.error(e.response.data);
					}
				} finally {
					this.loading = false;
				}
			}
		},

		async deleteUser(item) {
			if (confirm(`Willst du wirklich den Nutzer ${item.username} auf der Installation ${item.domain} löschen?`)) {
				this.loading = true;
				const response = await deleteUser(item.id, item.installationId, this.accessToken);

				if (response.code === 204) {
					this.$toast.success('Der Benutzer wurde erfolgreich gelöscht');
				} else {
					console.error(response.message);
					this.$toast.error(response.message);
				}

				this.loading = false;
			}
		},
	},
});
